.Header {
    position:fixed;
    pointer-events: none;
    z-index:1;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s, background-color 0.5s;
}

.Header.change {
    pointer-events: auto;
    visibility: visible;
    background-color: rgba(190, 190, 190, 0.9);
}

.Header.hide {
    pointer-events: none;
    opacity: 0
}

.Header.hide .navbar.logo {
    opacity: 0;
}


.menu.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    align-content: center;
    margin: auto;
    height: 100%;

    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s;
}

.menu.container.change {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
}

.menu.item {
    grid-column: span 3;
    grid-row: span 1;
    margin: 20px;
}

.navbar {
    padding: 2.5vmax;
    margin: 2.5vmax;
    pointer-events: auto;
}

.navbar.logo {
    width: 40px;
    float:left;
}


@keyframes logoNudge {
    0%   {transform:rotate(0deg);}
    20%  {transform:rotate(5deg);}
    40%   {transform:rotate(-5deg);}
    60%  {transform:rotate(5deg);}
    80%  {transform:rotate(-5deg);}
    100% {transform:rotate(0deg);}
}

.navbar.logo:hover {
    animation-name: logoNudge;
    animation-duration: .5s;
}

.navbar.menuicon{
    float:right;
}

.menuicon {
    display: inline-block;
    cursor: pointer;
}
  
.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin-top: 6px;
    margin-bottom: 6px;
    transition: 0.4s;
}

.menuicon:hover .bar1, 
.menuicon:hover .bar2, 
.menuicon:hover .bar3 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.menuicon.change:hover .bar1, 
.menuicon.change:hover .bar2, 
.menuicon.change:hover .bar3 {
    margin-top: 6px;
    margin-bottom: 6px;
}


.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}



.menu.item{
    font-size: 5vmin;
    font-weight: bold;
    font-family: "Helvetica";
    color:black;
}

.menu.item:link, .menu.item:visited {
    color: black;
    text-decoration: none;
}
  
.menu.item:hover, .menu.item:active {
    color: rgb(88, 88, 88);
    text-decoration: none;
}