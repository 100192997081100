.About {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.aboutWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;

}

.aboutPic {
    width:50vmin;
    max-width: 50vmin;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    filter: grayscale(40%);
    border-radius: 50%;
    margin-bottom: 1em;
}

.aboutPicLogo {
    position: absolute;
    opacity: 0;
    width: 25vmin;
    max-width: 25vmin;
    transform: translate(-37.5vmin, 9vmin);
    transition: opacity 1s;
}


@keyframes logoFlashing {
    0%   {opacity: 0;}
    50% {opacity: 0.6;}
    100% {opacity: 0;}
}

@media only screen and (max-width: 813px) {
    .aboutPicLogo.clicked {
        animation-name: logoFlashing;
        animation-duration: 1.5s;
        animation-iteration-count: 2;
    }
}


@media only screen and (min-width: 813px) {
    .aboutPicLogo.clicked {
        opacity: 0.6;
    }

    .aboutPicLogo.clicked:hover {
        animation-name:none;
    }

    .aboutPicLogo:hover {
        animation-name: logoFlashing;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;

    }
}


.aboutText {
    font-size: 4vmin;
}

.aboutSocial {
    font-size: 7vmin;
}

.aboutSocialItem {
    margin-left: 2vmin;
    margin-right: 2vmin;
}

.aboutSocialItem:link {
    text-decoration: none;
}

.aboutSocialItem:link, .aboutSocialItem:visited {
    color: black;
}
  
.aboutSocialItem:hover, .aboutSocialItem:active {
    color: grey;
}