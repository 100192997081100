.Work.wrapper {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto;
    z-index: -1;
}

.WorkItem {
    grid-column: span 9;
    width: 100%;
    height: auto;
}

#listdivider {
    width: 100vw;
    border-top: 3px solid black
}

@media only screen and (min-width: 1025px) {
    .Work.wrapper {
        display: block;
        text-align: left;
        height: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;

    }  

    .Work.wrapper.active a {
        pointer-events: none;
    }
    
    .WorkItem {
        display: inline-block;
        white-space: normal;
        text-align: center; 
        height: 100%;
        width: min-content;
    }

    #listdivider {
        display: none;
    }
    
}


