body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f8;
  font-family: "Helvetica";
}

::-webkit-scrollbar { 
  display: none; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Landing{
    position: absolute;
    width: 100%;
    height: 100%;
}

.landingCentering{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vmin;
    
    align-items: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}

.landing.logo {
    position:relative;
    width: 30vmin;
    max-width: 200px;
    margin-bottom: 2vmin;
}

@-webkit-keyframes logoRotate {
    0%   {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    25%  {-webkit-transform:rotate(-30deg);transform:rotate(-30deg);}
    100% {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}

@keyframes logoRotate {
    0%   {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    25%  {-webkit-transform:rotate(-30deg);transform:rotate(-30deg);}
    100% {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}
  
.landing.logo.active {
    -webkit-animation-name: logoRotate;
            animation-name: logoRotate;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}

.landingWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}


.landingWrapper .landing.item {
    margin: 1.5vmin;
}


.landing.item{
    line-height: 5vmin;
    font-size: 5vmin;
    font-weight: bold;
    font-family: "Helvetica"
}

.landing.item.social{
    line-height: 7vmin;
    font-size: 7vmin;
    margin-top: 3vmin;
    margin-left: 0.3em;
    margin-right: 0.3em;
}

.landing.item:link {
    text-decoration: none;
}

.landing.item:link, .landing.item:visited {
    color: black;
}
  
.landing.item:hover, .landing.item:active {
    color: grey;
}
.WorkItem {
    position: relative;
    background-color: white;
}

.WorkItem:hover .work.image{
    opacity: 0.4;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

@media only screen and (min-width: 1025px) {
    .WorkItemDesc {
        position: absolute;
        opacity: 0;
        
    }

    .WorkItem:hover .WorkItemDesc {
        position:relative;
        opacity: 1;
        transition: opacity 0.5s ease;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
    }
}

.worklink:link, .worklink:visited {
    color: black;
    text-decoration: none;
}

.worklink:hover, .worklink:active {
    color: black;
    text-decoration: none;
}


.workImageContainer {
    display: flex; 
    align-items: center;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    border: 3px solid black;
    border-bottom: 0px
}

@media only screen and (min-width: 1025px) {
    .workImageContainer {
        display:block;
        border-bottom: 5px solid black;
        height: 100%;
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
    }
}

.work.image {
    width: 100%;
    height: auto;
    opacity: 0.8;
    transition: 0.5s ease;
}

@media only screen and (min-width: 1025px) {
    .work.image {
        height: 100%;
        width: auto;
    }
}

.image.landscape {
    display: inline;
    display: initial;
}

.image.portrait {
    display: none;
}

@media only screen and (min-width: 1025px) {
    .image.landscape {
        display: none;
    }

    .image.portrait {
        display: inline;
        display: initial;
    }
}

.WorkItemText {
    width: 70%;
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    background-color: rgba(160, 160, 160, 0.904);
    padding: 2%;

    font-size: 100%;
    font-weight: bold;
    font-family: "Helvetica";

    transition: 0.5s ease;
}
.Work.wrapper {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto;
    z-index: -1;
}

.WorkItem {
    grid-column: span 9;
    width: 100%;
    height: auto;
}

#listdivider {
    width: 100vw;
    border-top: 3px solid black
}

@media only screen and (min-width: 1025px) {
    .Work.wrapper {
        display: block;
        text-align: left;
        height: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;

    }  

    .Work.wrapper.active a {
        pointer-events: none;
    }
    
    .WorkItem {
        display: inline-block;
        white-space: normal;
        text-align: center; 
        height: 100%;
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
    }

    #listdivider {
        display: none;
    }
    
}



.About {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.aboutWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;

}

.aboutPic {
    width:50vmin;
    max-width: 50vmin;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-filter: grayscale(40%);
            filter: grayscale(40%);
    border-radius: 50%;
    margin-bottom: 1em;
}

.aboutPicLogo {
    position: absolute;
    opacity: 0;
    width: 25vmin;
    max-width: 25vmin;
    -webkit-transform: translate(-37.5vmin, 9vmin);
            transform: translate(-37.5vmin, 9vmin);
    transition: opacity 1s;
}


@-webkit-keyframes logoFlashing {
    0%   {opacity: 0;}
    50% {opacity: 0.6;}
    100% {opacity: 0;}
}


@keyframes logoFlashing {
    0%   {opacity: 0;}
    50% {opacity: 0.6;}
    100% {opacity: 0;}
}

@media only screen and (max-width: 813px) {
    .aboutPicLogo.clicked {
        -webkit-animation-name: logoFlashing;
                animation-name: logoFlashing;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: 2;
                animation-iteration-count: 2;
    }
}


@media only screen and (min-width: 813px) {
    .aboutPicLogo.clicked {
        opacity: 0.6;
    }

    .aboutPicLogo.clicked:hover {
        -webkit-animation-name:none;
                animation-name:none;
    }

    .aboutPicLogo:hover {
        -webkit-animation-name: logoFlashing;
                animation-name: logoFlashing;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;

    }
}


.aboutText {
    font-size: 4vmin;
}

.aboutSocial {
    font-size: 7vmin;
}

.aboutSocialItem {
    margin-left: 2vmin;
    margin-right: 2vmin;
}

.aboutSocialItem:link {
    text-decoration: none;
}

.aboutSocialItem:link, .aboutSocialItem:visited {
    color: black;
}
  
.aboutSocialItem:hover, .aboutSocialItem:active {
    color: grey;
}
@media screen  and (max-width:900px) and (orientation:portrait) {
    .Contact {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;    
    }
}


@media screen  and (max-width:900px) and (orientation:landscape) {
    .Contact {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: none;
        align-items: center;  
    }

    .contactHeadings {
        margin-top: 10vmax;  
    }
}

@media only screen and (min-width: 900px) {
    .Contact {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;    
    }
}


.contactHeadings {
    width: 90vmin;
    margin-bottom: 3em;  
}

.contactTitle{
    font-size: 5vmin;
}

.contactDesc{
    font-size: 4vmin;
}

.contactForm {
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 0.5em;
    justify-items: center;
    align-content: center;
}

.contactEmail {
    width: 80vmin;
    max-width: 20em;
    grid-column: span 3;

    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid black;
    border-radius: 4px;
}

.contactText {
    width: 80vw;
    max-width: 50em;
    height: 12em;
    max-height: 40vmin;
    grid-column: span 3;

    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid black;
    border-radius: 4px;
}

.contactEmail, .contactText {
    font-family: monospace;
    background-color: rgb(255, 255, 255);
    font-size: 4vmin;
}

@media only screen and (min-width: 900px) {
    .contactEmail, .contactText {
        font-family: monospace;
        background-color: rgb(255, 255, 255);
        font-size: 140%;
    }
    
}


.contactEmail:focus, .contactText:focus {
    background-color: rgb(250, 250, 250);
}

.contactSend {
    width: 6em;
    height: 3em;
    grid-column: 2;

    font-weight: bold;
    font-family: monospace;
    font-size: 14;

    display: inline-block;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: black;
    background-color: rgb(209, 209, 209);
    border: none;
    border-radius: 15px;
    box-shadow: 0 5px #999;
    margin-bottom: 4em;
}


.contactSend span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.contactSend:hover span {
    padding-right: 1em;
  }
  

.contactSend span:after {
    position: absolute;
    content: '\BB';
    opacity: 0;
    top: -0.06em;
    right: -1em;
    transition: 0.5s;
}

.contactSend:hover span:after {
    opacity: 1;
    right: 0;
}
  
  
.contactSend:active {
    background-color: rgb(209, 209, 209);
    box-shadow: 0 2px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.Header {
    position:fixed;
    pointer-events: none;
    z-index:1;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s, background-color 0.5s;
}

.Header.change {
    pointer-events: auto;
    visibility: visible;
    background-color: rgba(190, 190, 190, 0.9);
}

.Header.hide {
    pointer-events: none;
    opacity: 0
}

.Header.hide .navbar.logo {
    opacity: 0;
}


.menu.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    align-content: center;
    margin: auto;
    height: 100%;

    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s;
}

.menu.container.change {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
}

.menu.item {
    grid-column: span 3;
    grid-row: span 1;
    margin: 20px;
}

.navbar {
    padding: 2.5vmax;
    margin: 2.5vmax;
    pointer-events: auto;
}

.navbar.logo {
    width: 40px;
    float:left;
}


@-webkit-keyframes logoNudge {
    0%   {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    20%  {-webkit-transform:rotate(5deg);transform:rotate(5deg);}
    40%   {-webkit-transform:rotate(-5deg);transform:rotate(-5deg);}
    60%  {-webkit-transform:rotate(5deg);transform:rotate(5deg);}
    80%  {-webkit-transform:rotate(-5deg);transform:rotate(-5deg);}
    100% {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
}


@keyframes logoNudge {
    0%   {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    20%  {-webkit-transform:rotate(5deg);transform:rotate(5deg);}
    40%   {-webkit-transform:rotate(-5deg);transform:rotate(-5deg);}
    60%  {-webkit-transform:rotate(5deg);transform:rotate(5deg);}
    80%  {-webkit-transform:rotate(-5deg);transform:rotate(-5deg);}
    100% {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
}

.navbar.logo:hover {
    -webkit-animation-name: logoNudge;
            animation-name: logoNudge;
    -webkit-animation-duration: .5s;
            animation-duration: .5s;
}

.navbar.menuicon{
    float:right;
}

.menuicon {
    display: inline-block;
    cursor: pointer;
}
  
.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin-top: 6px;
    margin-bottom: 6px;
    transition: 0.4s;
}

.menuicon:hover .bar1, 
.menuicon:hover .bar2, 
.menuicon:hover .bar3 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.menuicon.change:hover .bar1, 
.menuicon.change:hover .bar2, 
.menuicon.change:hover .bar3 {
    margin-top: 6px;
    margin-bottom: 6px;
}


.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}



.menu.item{
    font-size: 5vmin;
    font-weight: bold;
    font-family: "Helvetica";
    color:black;
}

.menu.item:link, .menu.item:visited {
    color: black;
    text-decoration: none;
}
  
.menu.item:hover, .menu.item:active {
    color: rgb(88, 88, 88);
    text-decoration: none;
}
.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.page-enter .Work,
.page-enter .Contact,
.page-enter .About {
  opacity: 0;
  transition: opacity 1s ease-in-out;

}
.page-enter-active .Work,
.page-enter-active .Contact,
.page-enter-active .About {
  opacity: 1;
}

.page-exit .Work,
.page-exit .Contact,
.page-exit .About {
  position:absolute;
  pointer-events: none;
  margin: 0;
  left: 0;
  top: 0;
  transition: opacity 0.5s;
}

.page-exit-active .Work,
.page-exit-active .Contact,
.page-exit-active .About {
  opacity:0;
}


.page-enter .landing.item {
  opacity: 0;
  transition: opacity 0.4s ease-in;
  transition-delay: 0.6s;
}

.page-enter-active .landing.item {
  opacity: 1;
  pointer-events: none;
}


@-webkit-keyframes logoHeaderToLanding {
  0%   {-webkit-transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(0deg);transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(0deg);}
  25%  {-webkit-transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(-30deg);transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(-30deg);}
  100% {-webkit-transform:translate(
                  0,
                  0)
                  rotate(360deg);transform:translate(
                  0,
                  0)
                  rotate(360deg);}
}


@keyframes logoHeaderToLanding {
  0%   {-webkit-transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(0deg);transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(0deg);}
  25%  {-webkit-transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(-30deg);transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(-30deg);}
  100% {-webkit-transform:translate(
                  0,
                  0)
                  rotate(360deg);transform:translate(
                  0,
                  0)
                  rotate(360deg);}
}

.page-enter .landing.item.logo {
  position: relative;
  opacity: 1;
  -webkit-animation-name: logoHeaderToLanding;
          animation-name: logoHeaderToLanding;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  width: 40px;
  transition: width 0.45s ease-in;
  transition-delay: 0.15s;
}

.page-enter-active .landing.item.logo {
  -webkit-transform: none;
          transform: none;
  width: 30vmin;
}


.page-exit .landing.item.logo {
  z-index: 15;
  pointer-events: none;
  transition: -webkit-transform .5s linear;
  transition: transform .5s linear;
  transition: transform .5s linear, -webkit-transform .5s linear;
  
}

.page-exit-active .landing.item.logo {
  -webkit-transform: scale(20);
          transform: scale(20);
}

.page-exit .Landing {
  position:absolute;
  margin: 0;
  left: 0;
  top: 0;
  z-index: 14;
  background-color: #f9f9f8;
  transition: opacity .5s ease-in;
}

.page-exit-active .Landing {
  opacity: 0;
}

.page-exit .landingWrapper {
  pointer-events: none;
  transition: opacity .2s ease-out;
}

.page-exit-active .landingWrapper {
  opacity: 0;
}

