.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.page-enter .Work,
.page-enter .Contact,
.page-enter .About {
  opacity: 0;
  transition: opacity 1s ease-in-out;

}
.page-enter-active .Work,
.page-enter-active .Contact,
.page-enter-active .About {
  opacity: 1;
}

.page-exit .Work,
.page-exit .Contact,
.page-exit .About {
  position:absolute;
  pointer-events: none;
  margin: 0;
  left: 0;
  top: 0;
  transition: opacity 0.5s;
}

.page-exit-active .Work,
.page-exit-active .Contact,
.page-exit-active .About {
  opacity:0;
}


.page-enter .landing.item {
  opacity: 0;
  transition: opacity 0.4s ease-in;
  transition-delay: 0.6s;
}

.page-enter-active .landing.item {
  opacity: 1;
  pointer-events: none;
}


@-webkit-keyframes logoHeaderToLanding {
  0%   {-webkit-transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(0deg);transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(0deg);}
  25%  {-webkit-transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(-30deg);transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(-30deg);}
  100% {-webkit-transform:translate(
                  0,
                  0)
                  rotate(360deg);transform:translate(
                  0,
                  0)
                  rotate(360deg);}
}


@keyframes logoHeaderToLanding {
  0%   {-webkit-transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(0deg);transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(0deg);}
  25%  {-webkit-transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(-30deg);transform:translate(
                    calc( 20px + 5vmax - 50vw), 
                    /* calculating size of landing box */
                    calc( 5vmax + 
                        (80vmin / 2) - 50vh ))
                    rotate(-30deg);}
  100% {-webkit-transform:translate(
                  0,
                  0)
                  rotate(360deg);transform:translate(
                  0,
                  0)
                  rotate(360deg);}
}

.page-enter .landing.item.logo {
  position: relative;
  opacity: 1;
  -webkit-animation-name: logoHeaderToLanding;
          animation-name: logoHeaderToLanding;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  width: 40px;
  transition: width 0.45s ease-in;
  transition-delay: 0.15s;
}

.page-enter-active .landing.item.logo {
  -webkit-transform: none;
          transform: none;
  width: 30vmin;
}


.page-exit .landing.item.logo {
  z-index: 15;
  pointer-events: none;
  transition: -webkit-transform .5s linear;
  transition: transform .5s linear;
  transition: transform .5s linear, -webkit-transform .5s linear;
  
}

.page-exit-active .landing.item.logo {
  -webkit-transform: scale(20);
          transform: scale(20);
}

.page-exit .Landing {
  position:absolute;
  margin: 0;
  left: 0;
  top: 0;
  z-index: 14;
  background-color: #f9f9f8;
  transition: opacity .5s ease-in;
}

.page-exit-active .Landing {
  opacity: 0;
}

.page-exit .landingWrapper {
  pointer-events: none;
  transition: opacity .2s ease-out;
}

.page-exit-active .landingWrapper {
  opacity: 0;
}
