.Landing{
    position: absolute;
    width: 100%;
    height: 100%;
}

.landingCentering{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vmin;
    
    align-items: center;
    top: 50%;
    transform: translateY(-50%)
}

.landing.logo {
    position:relative;
    width: 30vmin;
    max-width: 200px;
    margin-bottom: 2vmin;
}

@keyframes logoRotate {
    0%   {transform:rotate(0deg);}
    25%  {transform:rotate(-30deg);}
    100% {transform:rotate(360deg);}
}
  
.landing.logo.active {
    animation-name: logoRotate;
    animation-duration: 1s;
}

.landingWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}


.landingWrapper .landing.item {
    margin: 1.5vmin;
}


.landing.item{
    line-height: 5vmin;
    font-size: 5vmin;
    font-weight: bold;
    font-family: "Helvetica"
}

.landing.item.social{
    line-height: 7vmin;
    font-size: 7vmin;
    margin-top: 3vmin;
    margin-left: 0.3em;
    margin-right: 0.3em;
}

.landing.item:link {
    text-decoration: none;
}

.landing.item:link, .landing.item:visited {
    color: black;
}
  
.landing.item:hover, .landing.item:active {
    color: grey;
}