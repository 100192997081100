.WorkItem {
    position: relative;
    background-color: white;
}

.WorkItem:hover .work.image{
    opacity: 0.4;
    backface-visibility: hidden;
}

@media only screen and (min-width: 1025px) {
    .WorkItemDesc {
        position: absolute;
        opacity: 0;
        
    }

    .WorkItem:hover .WorkItemDesc {
        position:relative;
        opacity: 1;
        transition: opacity 0.5s ease;
        backface-visibility: hidden;
    }
}

.worklink:link, .worklink:visited {
    color: black;
    text-decoration: none;
}

.worklink:hover, .worklink:active {
    color: black;
    text-decoration: none;
}


.workImageContainer {
    display: flex; 
    align-items: center;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    border: 3px solid black;
    border-bottom: 0px
}

@media only screen and (min-width: 1025px) {
    .workImageContainer {
        display:block;
        border-bottom: 5px solid black;
        height: 100%;
        width: min-content;
    }
}

.work.image {
    width: 100%;
    height: auto;
    opacity: 0.8;
    transition: 0.5s ease;
}

@media only screen and (min-width: 1025px) {
    .work.image {
        height: 100%;
        width: auto;
    }
}

.image.landscape {
    display: initial;
}

.image.portrait {
    display: none;
}

@media only screen and (min-width: 1025px) {
    .image.landscape {
        display: none;
    }

    .image.portrait {
        display: initial;
    }
}

.WorkItemText {
    width: 70%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: rgba(160, 160, 160, 0.904);
    padding: 2%;

    font-size: 100%;
    font-weight: bold;
    font-family: "Helvetica";

    transition: 0.5s ease;
}